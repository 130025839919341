import { PermissionHandler } from 'app/auth/auth.service';

export interface MedisNavigationItem {
  id: string;
  type: 'item' | 'group';
  translate: string;
  icon?: string;
  svgIcon?: string;
  url?: string;
  queryParams?: Record<string, any>;
  children?: MedisNavigationItem[];
  userPermissions?: string[];
}

export function item(
  id: string,
  translate: string,
  icon: string,
  userPermissions?: string[]
): MedisNavigationItem {
  return { id, type: 'item', translate, icon, userPermissions };
}

export function urlItem(
  id: string,
  translate: string,
  icon: string,
  url: string,
  userPermissions?: string[]
): MedisNavigationItem {
  return { id, type: 'item', translate, icon, url, userPermissions };
}

export function newItem(
  id: string,
  translate: string,
  icon: string,
  url?: string,
  svgIcon?: string,
  userPermissions?: string[],
  queryParams?: Record<string, any>
): MedisNavigationItem {
  return { id, type: 'item', translate, icon, url, svgIcon, userPermissions, queryParams };
}

export function group(
  id: string,
  translate: string,
  icon: string,
  children: MedisNavigationItem[],
  userPermissions?: string[]
): MedisNavigationItem {
  return { id, type: 'group', translate, icon, children, userPermissions };
}

export function filter(
  navigationItems: MedisNavigationItem[],
  permissions: PermissionHandler
): MedisNavigationItem[] {
  const isEligible = (up?: string[]) => !up || permissions.has(...up);
  return navigationItems.filter((ng) => {
    ng.children = ng.children.filter((ni) => isEligible(ni.userPermissions));
    return isEligible(ng.userPermissions) && ng.children.length;
  });
}
