import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
  tap,
} from 'rxjs';

import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { environment } from 'app/../environments/environment';
import {
  AffiliationsRequest,
  AuthRequestActionRequest,
  AuthRequestService,
} from '../auth-request/auth-request.service';
import { BAuthRequest, BTeam, BUser } from '../user/user';
import { BRole } from '../role/role';
import { NomenclaturesService, Scopes } from '../nomenclature/nomenclatures.service';
import { UsersService } from '../user/users.service';
import { Affiliation } from 'app/common/common/affilication-picker/affiliation-picker.component';
import { BAffiliation } from '../user/affiliation.base';
import { EnvOrigin } from 'app/common/types';

const remoteHost = environment.syncRemoteHost;
const doThrow = (error: string): void => {
  throw error;
};
const resolveHost = (origin: EnvOrigin): string => (origin === 'remote' ? remoteHost : undefined);
function resolveEnvName(origin: EnvOrigin): string {
  const envName = origin === 'local' ? environment.name : environment.syncRemoteName;
  return envName.toUpperCase();
}

@Injectable({ providedIn: 'root' })
export class EnvironmentSyncService {
  constructor(
    private userService: UsersService,
    private authService: AuthRequestService,
    private nomenclatureService: NomenclaturesService,
    private dialog: GenericDialogService,
    private messageService: MessageHandlerService
  ) {}

  request(user: BUser, affiliations: Affiliation[], applyOnRemoteEnv: boolean): Observable<void> {
    const affRequest: AffiliationsRequest = Affiliation.toAffiliationsRequest(affiliations);
    const observables = [this.doRequest(user, affRequest, 'local')];
    if (applyOnRemoteEnv) {
      observables.push(this.requestRemote(user.username, affiliations));
    }
    return forkJoin(observables).pipe(map(() => {}));
  }

  revoke(
    req: BAuthRequest,
    user: BUser,
    userAssignedInteractionIds: number[]
  ): Observable<boolean> {
    const reqStatus = req.status.state.name;
    if (reqStatus !== 'approved') {
      return this.doRevoke(req.pk()).pipe(map(() => false));
    }
    return this.revokeApproved(
      user,
      req.pk(),
      req.requestTeam.code,
      req.requestRole.code,
      userAssignedInteractionIds
    );
  }

  revokeApproved(
    user: BUser,
    authReqId: number,
    teamCode: string,
    roleCode: string,
    userAssignedInteractionIds: number[] = []
  ): Observable<boolean> {
    const shouldDeactivate = user.isActive && this.hasSingleApprovedAuthRequest(user);
    return this.dialog
      .openRoleArchiveConfirmationWithRemoteOpt(shouldDeactivate, userAssignedInteractionIds)
      .pipe(
        switchMap((applyOnRemoteEnv) => {
          const deactivateId = shouldDeactivate ? user.pk() : undefined;
          const userHasAssignedInteractions = userAssignedInteractionIds.length > 0;
          const observables = [
            this.doRevoke(authReqId, deactivateId, 'local', userHasAssignedInteractions),
          ];
          if (applyOnRemoteEnv) {
            observables.push(this.revokeRemote(user.username, teamCode, roleCode));
          }
          return forkJoin(observables);
        }),
        map(() => shouldDeactivate)
      );
  }

  deactivate(user: BUser): Observable<void> {
    return this.dialog
      .openConfirmUserDeactivationWithRemoteOpt(user.displayInfo())
      .pipe(
        switchMap((applyOnRemoteEnv) => {
          const observables = [this.userService.deactivate(user.pk())];
          if (applyOnRemoteEnv) {
            observables.push(this.toggleActiveRemote(user.username, user.isActive));
          }
          return forkJoin(observables);
        })
      )
      .pipe(this.displayAnyError('local', of(), 'Deactivate'))
      .pipe(map(() => {}));
  }

  activate(user: BUser): Observable<boolean> {
    return this.dialog
      .openConfirmUserActivationWithRemoteOpt(user.displayInfo())
      .pipe(
        switchMap((applyOnRemoteEnv) => {
          const observables = [this.userService.deactivate(user.pk())];
          if (applyOnRemoteEnv) {
            observables.push(this.toggleActiveRemote(user.username, user.isActive));
          }
          return forkJoin(observables);
        })
      )
      .pipe(this.displayAnyError('local', of(), 'Activate'))
      .pipe(map((res) => res[0] === 'activated-legacy'));
  }

  create(data: UsersService.CreateUser, aff: Affiliation[], remote: boolean): Observable<number> {
    data.affiliations = Affiliation.toAffiliationsRequest(aff);
    const observables = [this.userService.create(data)];
    if (remote) {
      const createRemote = this.mapAffiliations(aff, 'remote')
        .pipe(tap((afReq) => (data.affiliations = afReq)))
        .pipe(switchMap((_afReq) => this.userService.create(data, remoteHost)))
        .pipe(this.displayAnyError('remote', of(-1), 'Create User'));
      observables.push(createRemote);
    }
    return combineLatest(observables).pipe(map((res) => res[0]));
  }

  requestOnEnv(user: BUser, affiliation: BAffiliation, origin: EnvOrigin): Observable<number[]> {
    const affiliations: Affiliation[] = Affiliation.fromBAffiliations([affiliation]);
    return this.mapAffiliations(affiliations, origin)
      .pipe(switchMap((afReq) => this.doRequest(user, afReq, origin)))
      .pipe(this.displayAnyError(origin, of(), 'Request Role'));
  }

  revokeOnEnv(authReqId: number, user: BUser, origin: EnvOrigin): Observable<boolean> {
    const shouldDeactivate = user.isActive && this.hasSingleApprovedAuthRequest(user);
    const deactivateId = shouldDeactivate ? user.pk() : undefined;
    return this.dialog
      .openRoleArchiveConfirmation(shouldDeactivate)
      .getSaveSubject()
      .pipe(switchMap(() => this.doRevoke(authReqId, deactivateId, origin)))
      .pipe(map(() => shouldDeactivate))
      .pipe(this.displayAndRethrowAnyError(origin, 'Archive Role'));
  }

  deactivateOnEnv(user: BUser, origin: EnvOrigin): Observable<void> {
    return this.dialog
      .openConfirmUserDeactivation(user.displayInfo())
      .getSaveSubject()
      .pipe(switchMap(() => this.userService.deactivate(user.pk(), resolveHost(origin))))
      .pipe(this.displayAnyError(origin, of(), 'Deactivate'))
      .pipe(map(() => {}));
  }

  activateOnEnv(user: BUser, origin: EnvOrigin): Observable<boolean> {
    return this.dialog
      .openConfirmUserActivation(user.displayInfo())
      .getSaveSubject()
      .pipe(switchMap(() => this.userService.deactivate(user.pk(), resolveHost(origin))))
      .pipe(this.displayAnyError(origin, of(), 'Activate'))
      .pipe(map((r) => r === 'activated-legacy'));
  }

  createOnEnv(user: BUser, origin: EnvOrigin): Observable<number> {
    return this.mapAffiliations(Affiliation.fromBAffiliations(user.affiliations), origin).pipe(
      switchMap((mappedAffiliationsRequest) => {
        const data: UsersService.CreateUser = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.username,
          affiliations: mappedAffiliationsRequest,
          password: 'False',
          welcomeEmail: true,
        };
        return this.userService.create(data, resolveHost(origin));
      }),
      this.displayAnyError(origin, of(), 'Create User')
    );
  }

  private doRevoke(
    authReqId: number,
    deactivateUserId?: number,
    origin: EnvOrigin = 'local',
    userHasAssignedInteractions: boolean = false
  ): Observable<void> {
    const request: AuthRequestActionRequest = {
      authRequestId: authReqId,
      deactivateUserId: deactivateUserId,
      userHasAssignedInteractions: userHasAssignedInteractions,
    };
    return this.authService.performAction(request, resolveHost(origin));
  }

  private doRequest(usr: BUser, ar: AffiliationsRequest, origin: EnvOrigin): Observable<number[]> {
    return this.authService.create(usr.pk(), ar, resolveHost(origin));
  }

  private hasSingleApprovedAuthRequest(user: BUser): boolean {
    if (user.authRequests.length === 0) {
      return user.affiliations.filter((a) => a.statusName === 'approved').length === 1;
    }
    return user.authRequests.filter((ar) => ar.status?.state?.name === 'approved').length === 1;
  }

  private revokeRemote(username: string, teamCode: string, roleCode: string): Observable<any> {
    return this.findRemoteUser(username)
      .pipe(
        switchMap((user) => {
          const userAff = user.affiliations;
          const aff = userAff.find((a) => a.team.code === teamCode && a.role.code === roleCode);
          if (!aff) {
            throw 'Auth request not found';
          }
          const shouldDeactivateRemote = user.isActive && this.hasSingleApprovedAuthRequest(user);
          const deactivateRemoteId = shouldDeactivateRemote ? user.pk() : undefined;
          return this.doRevoke(aff.authRequestId, deactivateRemoteId, 'remote');
        })
      )
      .pipe(this.displayAnyError('remote', of('continue'), 'Archive Role'));
  }

  private requestRemote(username: string, affiliations: Affiliation[]): Observable<any> {
    return combineLatest([
      this.findRemoteUser(username),
      this.mapAffiliations(affiliations, 'remote'),
    ])
      .pipe(switchMap(([user, afReq]) => this.doRequest(user, afReq, 'remote')))
      .pipe(this.displayAnyError('remote', of('continue'), 'Request Role'));
  }

  private toggleActiveRemote(username: string, expectedIsActive: boolean): Observable<string> {
    const action = expectedIsActive ? 'Deactivate' : 'Activate';
    const formatError = (user) => `User is already ${user.isActive ? 'active' : 'inactive'}`;
    return this.findRemoteUser(username, 'all')
      .pipe(tap((user) => user.isActive !== expectedIsActive && doThrow(formatError(user))))
      .pipe(switchMap((user) => this.userService.deactivate(user.pk(), remoteHost)))
      .pipe(this.displayAnyError('remote', of('continue'), action));
  }

  private mapAffiliations(src: Affiliation[], origin: EnvOrigin): Observable<AffiliationsRequest> {
    const formatMissingError = (missingTeams: BTeam[], missingRoles: BRole[]): string => {
      const map = (arr: Array<BTeam | BRole>) => arr.map((i) => `${i.code} (${i.name})`).join(',');
      const teamsErr = missingTeams.length ? ` Teams: ${map(missingTeams)}` : '';
      const rolesErr = missingRoles.length ? ` Roles: ${map(missingRoles)}` : '';
      return teamsErr + rolesErr;
    };

    const map = (teams: BTeam[], roles: BRole[]): Affiliation[] => {
      const missingTeams: BTeam[] = [];
      const missingRoles: BRole[] = [];
      const mapped: Affiliation[] = [];
      for (const sa of src) {
        const mappedTeam = teams.find((t) => t.code === sa.team.code);
        const mappedRoles: BRole[] = [];
        if (!mappedTeam) {
          missingTeams.push(sa.team);
        } else {
          for (const sr of sa.roles) {
            const mappedRole = roles.find((r) => r.code === sr.code);
            if (!mappedRole) {
              missingRoles.push(sr);
            } else {
              mappedRoles.push(mappedRole);
            }
          }
        }
        if (mappedTeam && mappedRoles.length) {
          mapped.push({ team: mappedTeam, roles: mappedRoles });
        }
      }
      const missingErr = formatMissingError(missingTeams, missingRoles);
      if (!mapped.length) {
        throw 'No affiliations can be resolved due to missing -' + missingErr;
      }
      if (missingTeams.length || missingRoles.length) {
        this.messageService.error("The following affiliations can't be mapped -" + missingErr);
      }
      return mapped;
    };

    return this.nomenclatureService
      .get(new Scopes().teams().roles(), false, resolveHost(origin))
      .pipe(switchMap((noms) => of(map(noms.teams, noms.roles))))
      .pipe(switchMap((mapped) => of(Affiliation.toAffiliationsRequest(mapped))));
  }

  private findRemoteUser(username: string, status: 'all' | 'active' = 'all'): Observable<BUser> {
    return this.userService
      .findUser(username, status, remoteHost)
      .pipe(tap((user) => !user && doThrow('User not found')));
  }

  private displayAnyError<T>(
    origin: EnvOrigin,
    resp: Observable<T>,
    action: string
  ): OperatorFunction<any, T> {
    return catchError((err) => {
      this.display(err, action, origin);
      return resp;
    });
  }

  private displayAndRethrowAnyError<T>(
    origin: EnvOrigin,
    action: string
  ): OperatorFunction<any, T> {
    return catchError((err) => {
      this.display(err, action, origin);
      throw err;
    });
  }

  private display(err: HttpErrorResponse | any, action: string, origin: EnvOrigin): void {
    const prefix = `[${resolveEnvName(origin)}] ${action}`;
    if (err instanceof HttpErrorResponse) {
      this.messageService.httpError(prefix, err);
    } else {
      this.messageService.error(`(${prefix}): ${JSON.stringify(err)}`);
    }
  }
}
