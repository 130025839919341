import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  OperatorFunction,
  Subject,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { apiUrl } from 'app/common/url-resolver.service';
import { BUser } from 'app/modules/data-model/user/user';
import { StorageService } from 'app/common/storage.service';
import { RollbarHandler } from 'app/common/rollbar-handler';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { resolveNavItems } from 'app/common/util/navigation';

export class PermissionHandler {
  private userPermissions: string[];
  private _hasTeamWithEnquiryAnswerApproval: boolean;

  constructor(user: any) {
    this.userPermissions = user.permissions || [];
    this._hasTeamWithEnquiryAnswerApproval = user.hasTeamWithEnquiryAnswerApproval || false;
  }

  has(...permissions: string[]): boolean {
    return this.userPermissions.some((up) => permissions.includes(up));
  }

  get hasTeamWithEnquiryAnswerApproval(): boolean {
    return this._hasTeamWithEnquiryAnswerApproval;
  }

  isForActualUser(): boolean {
    return this !== NO_USER_PERMISSIONS;
  }
}

const NO_USER_PERMISSIONS = new PermissionHandler({});

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  public readonly USER_ACCOUNT_IS_DISABLED = 'User account is disabled.';
  public user = new BehaviorSubject<BUser>(undefined);
  public permissions = new BehaviorSubject<PermissionHandler>(NO_USER_PERMISSIONS);

  private readonly loginUrl = apiUrl('auth', 'login/');
  private readonly refreshUrl = apiUrl('auth', 'refresh/');
  private readonly auditUrl = apiUrl('audit', 'write-log');
  private readonly loggedInUrl = apiUrl('users', 'loggedin');
  private subs: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private messageService: MessageHandlerService
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http
      .post(this.loginUrl, { username, password }, { withCredentials: true })
      .pipe(this.handleLoginResponse());
  }

  private handleLoginResponse(): OperatorFunction<any, boolean> {
    return map((response: any) => {
      // login successful if there's a jwt token in the response
      const token = response?.token;
      if (token) {
        // store jwt token in local storage to keep user logged in between page refreshes
        this.storage.setToken(token);
        // return true to indicate successful login
        this.fetchLoggedInUser();
        return true;
      }
      this.triggerUnauthenticated();
      // return false to indicate failed login
      return false;
    });
  }

  loginViaToken(token: string, userId: number): Observable<boolean> {
    return this.http.post(this.loginUrl, { token, userId }).pipe(this.handleLoginResponse());
  }

  getCurrentUserRoles(): string[] {
    const user = this.user.getValue();
    if (!user) {
      return [];
    }
    return Array.from(
      new Set(
        user.affiliations
          .filter((affiliation) => affiliation.isApproved())
          .map((affiliation) => affiliation.role.name)
      )
    );
  }

  fetchUserAndNavigateToPermittedPage(): void {
    this.subs.add(
      this.fetchLoggedInUser().subscribe((handler) => {
        this.navigateToPermittedPage(handler);
      })
    );
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.postAuditLog().subscribe();
    this.triggerUnauthenticated();
    this.storage.removeToken();
  }

  logoutAndRedirect(userIsDeactivated: boolean): void {
    this.triggerUnauthenticated();
    this.storage.removeToken();
    this.router.navigate(['/auth/login']).then(() => {
      if (userIsDeactivated) {
        this.messageService.info('Your account has been deactivated!');
      }
    });
  }

  hasToken(): boolean {
    const token = this.storage.getToken();
    return token != null && token != '';
  }

  async tokenIsValid(): Promise<boolean> {
    if (!this.hasToken()) {
      this.triggerUnauthenticated();
      return false;
    }
    const oldToken = this.storage.getToken();
    try {
      const res = await firstValueFrom(this.http.post<any>(this.refreshUrl, { token: oldToken }));
      if (res?.token) {
        this.storage.setToken(res.token);
        return true;
      } else {
        this.triggerUnauthenticated();
        return false;
      }
    } catch (e) {
      this.triggerUnauthenticated();
      return false;
    }
  }

  fetchLoggedInUser(): Subject<PermissionHandler> {
    const currentResult = new Subject<PermissionHandler>();
    this.http.get<any>(this.loggedInUrl).subscribe({
      next: (user) => {
        const parsed = BUser.fromRest(user);
        RollbarHandler.setCurrentUserId(parsed.pk());
        this.user.next(parsed);
        this.permissions.next(new PermissionHandler(user));
        currentResult.next(this.permissions.value);
      },
      error: (error) => {
        this.triggerUnauthenticated();
        console.error(error);
      },
    });
    return currentResult;
  }

  postAuditLog(): Observable<void> {
    const body = {
      action: 'Logout',
      entity: 'user',
      values: { original_value: null, new_value: 'Successful log out' },
    };
    return this.http.post<void>(this.auditUrl, body);
  }

  private navigateToPermittedPage(handler: PermissionHandler): void {
    const routes = resolveNavItems(handler)
      .map((r) => (r.href ? r : r.children.flat()))
      .flat();
    if (routes.length) {
      this.router.navigate(['/' + routes[0].href]);
    } else {
      this.logoutAndRedirect(false);
    }
  }

  private triggerUnauthenticated(): void {
    this.user.next(undefined);
    this.permissions.next(NO_USER_PERMISSIONS);
  }
}
