import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { parseAttr } from 'app/modules/data-model/data-model';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { BTeam, MTeam } from '../user/user';
import { getAllTeams, updateTeam } from './queries';
import { BaseDataService } from '../data-service';
import { createGuid } from 'app/common/uuid-generator';

import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';
import BTeamGroupFilter = MTeam.BTeamGroupFilter;

@Injectable({
  providedIn: 'root',
})
export class TeamService extends BaseDataService<BTeam> {
  query = getAllTeams;
  objectInstance = new BTeam({});
  fieldName = 'allTeams';
  teamChanged: Subject<BTeam> = new Subject<BTeam>();

  private readonly TEAMS_URL: string;
  private readonly TEAMS_GROUP_FILTERS_URL: string;

  constructor(
    private http: HttpClient,
    urlResolverService: UrlResolverService,
    injector: Injector,
    private messageService: MessageHandlerService
  ) {
    super(injector);
    this.TEAMS_URL = urlResolverService.misApiUrlForPath('/teams');
    this.TEAMS_GROUP_FILTERS_URL = urlResolverService.misApiUrlForPath('/teams/group-filters');
  }

  update(data: Object, successMessage = 'Team updated correctly.') {
    let mutationData = {};
    mutationData = Object.assign({}, data);
    mutationData['clientMutationId'] = createGuid();

    let mutation = this.apollo.mutate({
      mutation: updateTeam,
      variables: { params: mutationData },
    });
    mutation.subscribe(
      (response) => {
        if (response) {
          let responseData = response.data['updateTeam'];
          let updated = parseAttr<BTeam>(responseData, BTeam, 'team');
          this.teamChanged.next(updated);
          this.messageService.info(successMessage);
        } else {
          this.messageService.error('Team Edit: empty response');
        }
      },
      (error) => {
        this.messageService.error('(Interaction Edit) ' + error);
      }
    );
  }

  // REST Requests
  get(teamId: string): Observable<string> {
    return this.http.get<string>(`${this.TEAMS_URL}/${teamId}/`);
  }

  save(data: TeamService.TeamEdit): Observable<number> {
    if (!data.hasOwnProperty('id') || !data.id)
      return this.http.post<number>(`${this.TEAMS_URL}/`, data);
    else return this.http.put<number>(`${this.TEAMS_URL}/${data.id}/`, data);
  }

  delete(teamId: string): Observable<number> {
    return this.http.delete<number>(`${this.TEAMS_URL}/${teamId}/`);
  }

  createTeamGroupFilter(params: TeamService.TeamGroupFilterParams): Observable<void> {
    return this.http.post<void>(this.TEAMS_GROUP_FILTERS_URL, params);
  }

  editTeamGroupFilter(params: TeamService.TeamGroupFilterParams): Observable<void> {
    return this.http.put<void>(this.TEAMS_GROUP_FILTERS_URL, params);
  }

  deleteTeamGroupFilter(id: string): Observable<void> {
    return this.http.delete<void>(this.TEAMS_GROUP_FILTERS_URL, {
      params: { id },
    });
  }

  fetchTeamGroupFilter(teamId: string = null): Observable<BTeamGroupFilter[]> {
    return this.http
      .get<BTeamGroupFilter[]>(this.TEAMS_GROUP_FILTERS_URL, {
        params: omitBy({ teamId }, isNil),
      })
      .pipe(
        map((response) => {
          return BTeamGroupFilter.fromRestArray(response);
        })
      );
  }
}

export namespace TeamService {
  export type TeamEdit = {
    id: string;
    name: string;
    code: string;
    countries: Array<string>;
    default_language: string;
    product_groups: Array<string>;
    incoming_mailbox: number;
    outgoing_mailbox: number;
    segregated_data: boolean;
    restricted_team: boolean;
    private_team: boolean;
    timezone_code: string;
    notify_inquirer: boolean;
    parent_team: string;
    affiliations: Array<string>;
    auto_assignment_on: boolean;
    sales_full_answer_on_label: boolean;
    override_cluster_root: string;
    company: string;
    region: string;
    therapeuticAreas: string[];
    products: string[];
    countryProductsDefinition: { [countryId: string]: string[] };
    documentApprovalWithinEnqmed: boolean;
    notifySales: boolean;
    enquiryAnswerApproval: boolean;
  };

  export type TeamGroupFilterParams = {
    id?: string;
    name: string;
    teamIds: string[];
  };
}
