import { inject, Type } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, map, Observable } from 'rxjs';

import { userRoles } from '@mi-tool/consts';
import { AuthService } from 'app/auth/auth.service';
import { BUser } from 'app/modules/data-model/data-model.module';

export function hasPermission(...permissions: string[]): CanActivateFn {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> => {
    const router = inject(Router);
    return inject(AuthService)
      .permissions.pipe(filter((p) => p.isForActualUser()))
      .pipe(
        map((p) => {
          const canActivate = p.has(...permissions);
          if (!canActivate) {
            router.navigate(['access-denied']);
          }
          return canActivate;
        })
      );
  };
}

export function canApproveDocumentsGuard(): CanActivateFn {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> => {
    const router = inject(Router);
    return inject(AuthService)
      .user.pipe(filter((user) => !!user))
      .pipe(
        map((user) => {
          const canActivate = canApproveDocuments(user);
          if (!canActivate) {
            router.navigate(['access-denied']);
          }
          return canActivate;
        })
      );
  };
}

export function canApproveDocuments(user: BUser): boolean {
  if (!user) {
    return false;
  }

  if (user.isSysAdmin()) {
    return true;
  }

  const allowedRoles = [...userRoles.mim, ...userRoles.documentProcessing];
  return user.affiliations.some(
    (affiliation) =>
      affiliation.team.documentApprovalWithinEnqmed && allowedRoles.includes(affiliation.role.name)
  );
}

export function route(path: string, component: Type<any>, ...canActivate: CanActivateFn[]): Route;
export function route(path: string, component: Type<any>, ...permission: string[]): Route;
export function route(
  path: string,
  component: Type<any>,
  ...guardDef: string[] | CanActivateFn[]
): Route {
  const route: Route = { path, component };
  if (guardDef?.length) {
    if (typeof guardDef[0] === 'string') {
      route.canActivate = [hasPermission(...(guardDef as string[]))];
    } else {
      route.canActivate = guardDef;
    }
  }
  return route;
}
