// The const names and values of this file are supposed to match with mis/model/consts.py
// on backend - this would allow easier search where permission is used
export const PERM_VIEW_ENQUIRIES = 'View Enquiries';
export const PERM_PROCESS_ENQUIRIES = 'Process Enquiries';
export const PERM_APPROVE_ENQUIRIES = 'Approve Enquiries';
export const PERM_ANSWER_WITHOUT_APPROVAL = 'Answer Without Approval';
export const PERM_LIMIT_VIEW_ENQUIRIES = 'Limit View Enquiries';
export const PERM_VIEW_ENQUIRY_DRAFTS = 'View Enquiry Drafts';
export const PERM_VIEW_ENQUIRY_SHARE = 'View Enquiry Share';
export const PERM_VIEW_ENQUIRER_NOTIFICATIONS = 'View Enquirer Notifications';
export const PERM_VIEW_ENQUIRY_ANSWER_BCC = 'View Enquiry Answer BCC';
export const PERM_VIEW_REPOSITORY = 'View Repository';
export const PERM_MANAGE_DOCUMENTS = 'Manage Documents';
export const PERM_VIEW_REPORTS = 'View Reports';
export const PERM_VIEW_THERAPEUTIC_AREAS = 'View Therapeutic Areas';
export const PERM_VIEW_USER_ADMIN = 'View User Admin';
export const PERM_MANAGE_ALL_USERS = 'Manage All Users';
export const PERM_MANAGE_OWN_USERS = 'Manage Own Users';
export const PERM_VIEW_HCP_ADMIN = 'View HCP Admin';
export const PERM_VIEW_EVENTS = 'View Events';
export const PERM_VIEW_MAILBOXES = 'View Mailboxes';
export const PERM_VIEW_TASKS = 'View Tasks';
export const PERM_VIEW_MIGRATION_ERRORS = 'View Migration Errors';
export const PERM_VIEW_METADATA = 'View Metadata';
export const PERM_VIEW_TEAMS = 'View Teams';
export const PERM_VIEW_AUTH_REQUESTS = 'View Auth Requests';
export const PERM_VIEW_ROLES = 'View Roles';
export const PERM_ADMINISTRATE_SYSTEM = 'Administrate System';

export const PERMS_ACCESS_ENQUIRIES = [PERM_VIEW_ENQUIRIES, PERM_PROCESS_ENQUIRIES];
