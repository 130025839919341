import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiUrl } from 'app/common/url-resolver.service';
import { BMailbox } from './mailbox';
import { PaginationParams, SimplePage } from 'app/common/types';
import { BTeam } from '../user/user';
import { isNil, omitBy } from 'lodash';
import { BDMMessage, BDMMessageType } from '../message/message';

@Injectable({ providedIn: 'root' })
export class MailboxService {
  private readonly URL_MAILBOXES = apiUrl('mailboxes/');
  private readonly URL_MAILBOXES_TEAMS = apiUrl('mailboxes', 'teams');
  private readonly URL_MAILBOXES_ALL = apiUrl('mailboxes', 'all');
  private readonly URL_MAILBOXES_MESSAGES = apiUrl('mailboxes', 'messages');
  private readonly URL_MAILBOXES_MESSAGES_TYPES = apiUrl('mailboxes', 'messages', 'types');

  constructor(private http: HttpClient) {}

  getAll(): Observable<BMailbox[]> {
    return this.http
      .get<BMailbox[]>(this.URL_MAILBOXES_ALL)
      .pipe(map((mailboxes) => BMailbox.fromRestArray(mailboxes)));
  }

  get(params: MailboxParams): Observable<SimplePage<BMailbox>> {
    return this.http
      .get<SimplePage<BMailbox>>(this.URL_MAILBOXES, { params: omitBy(params, isNil) })
      .pipe(map((res) => ({ ...res, records: BMailbox.fromRestArray(res.records) })));
  }

  create(mailboxData: MailboxData): Observable<void> {
    return this.http.post<void>(this.URL_MAILBOXES, mailboxData);
  }

  update(mailboxData: Partial<MailboxData>): Observable<void> {
    return this.http.put<void>(this.URL_MAILBOXES, mailboxData);
  }

  verifySetup(id: number): Observable<string> {
    return this.http.get<Object>(this.URL_MAILBOXES + `${id}/verify-setup`).pipe(
      map((response) =>
        Object.entries(response)
          .map(([key, val]) => `${key}: <b>${val}</b>`)
          .join('<br>')
      )
    );
  }

  getTeamsWithMailboxes(): Observable<BTeam[]> {
    return this.http
      .get<BTeam[]>(this.URL_MAILBOXES_TEAMS)
      .pipe(map((teams) => BTeam.fromRestArray(teams)));
  }

  updateTeamMailbox(data: UpdateTeamMailboxData): Observable<void> {
    return this.http.put<void>(this.URL_MAILBOXES_TEAMS, data);
  }

  getMessage(msgId: number): Observable<BDMMessage> {
    return this.http
      .get<BDMMessage>(this.URL_MAILBOXES_MESSAGES, { params: { msgId } })
      .pipe(map((res) => BDMMessage.fromRest(res)));
  }

  getMessages(params: MessageParams): Observable<SimplePage<BDMMessage>> {
    return this.http
      .get<SimplePage<BDMMessage>>(this.URL_MAILBOXES_MESSAGES, { params: omitBy(params, isNil) })
      .pipe(map((res) => ({ ...res, records: BDMMessage.fromRestArray(res.records) })));
  }

  getMessageTypes(): Observable<BDMMessageType[]> {
    return this.http
      .get<BDMMessageType[]>(this.URL_MAILBOXES_MESSAGES_TYPES)
      .pipe(map((types) => BDMMessageType.fromRestArray(types)));
  }
}

export type MailboxData = {
  id?: number;
  name: string;
  uri: string;
  fromEmail: string;
  active: boolean;
};

export type UpdateTeamMailboxData = {
  teamId: string;
  mailboxId: number;
} & ({ incomingMailboxId: number } | { outgoingMailboxId: number });

export type MailboxParams = PaginationParams & {
  orderBy?: string;
  id?: number;
  name?: string;
  uri?: string;
  fromEmail?: string;
  lastPolling?: string;
  status?: string;
};

export type MessageParams = PaginationParams & {
  orderBy?: string;
  id?: number;
  inReplyToId?: number;
  interactionId?: number;
  mailboxIds?: string;
  outgoing?: string;
  from?: string;
  to?: string;
  subject?: string;
  typeIds?: string;
  createdOn?: string;
};
