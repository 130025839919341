import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';
import { apiUrl } from 'app/common/url-resolver.service';
import { BUserTemplate } from './user-template';

@Injectable()
export class UserTemplatesService {
  private readonly URL: string = apiUrl('templates', 'users');

  constructor(private http: HttpClient) {}

  get(teamId?: number, roleId?: number): Observable<BUserTemplate[]> {
    const query = new Ajax.QueryParams()
      .addIfPresent('teamId', teamId)
      .addIfPresent('roleId', roleId)
      .toString();
    return this.http
      .get<BUserTemplate[]>(this.URL + query)
      .pipe(map((resp) => BUserTemplate.fromRestArray(resp)));
  }

  create(data: UserTemplateCreate): Observable<number> {
    return this.http.post<number>(this.URL, data);
  }

  set(userId: number, templateId: number): Observable<string> {
    return this.http.patch<string>(this.URL, { userId: userId, templateId: templateId });
  }

  setAsDefault(templateId: number, roleId: number, teamId: number): Observable<string> {
    return this.http.put<string>(this.URL, {
      templateId: templateId,
      roleId: roleId,
      teamId: teamId,
    });
  }

  archive(templateId: number): Observable<string> {
    return this.http.delete<string>(this.URL + '?templateId=' + templateId);
  }
}

export type UserTemplateCreate = {
  userId: number;
  name: string;
  includeNotifications: boolean;
  includeTeams: boolean;
  overrideNotifications: boolean;
};
