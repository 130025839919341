import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth/auth.guard';
import { MyDraftsComponent } from './modules/my-drafts/my-drafts.component';
import { PageAccessDeniedComponent } from './page-access-denied/page-access-denied.component';
import { PERMS_ACCESS_ENQUIRIES } from 'app/common/common/constants/permissions';
import { hasPermission } from './common/util/permission.guard';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'inq',
    loadChildren: () =>
      import('./modules/inquiries/inquiries.module').then((m) => m.InquiriesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'spam',
    loadChildren: () => import('./modules/spam/spam.module').then((m) => m.SpamModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./modules/inquiry-detail/inquiry-detail.module').then((m) => m.InquiryDetailModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'drafts',
    component: MyDraftsComponent,
    canActivate: [AuthGuard, hasPermission(...PERMS_ACCESS_ENQUIRIES)],
  },
  {
    path: 'detail',
    loadChildren: () =>
      import('./modules/inquiry-detail/inquiry-detail.module').then((m) => m.InquiryDetailModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'repository',
    loadChildren: () =>
      import('./modules/repository/repository.module').then((m) => m.RepositoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./modules/administration/administration.module').then((m) => m.AdministrationModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/inq/my-teams-open-inq', pathMatch: 'full' },
  { path: 'access-denied', component: PageAccessDeniedComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
