import { base64ToPK } from '../data-model';

export class BSpamMessage {
  id: string;
  createdTs: Date;
  messageSubject: string;
  messageFromHeader: string;
  messageContent: string;
  teamName: string;

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }

  static fromRest(json: any): BSpamMessage {
    return Object.assign(new BSpamMessage(), json);
  }

  static fromRestArray(json: any[]): BSpamMessage[] {
    return json && json.map((v) => BSpamMessage.fromRest(v));
  }
}
