<ng-container>
  <div *ngIf="item.translate" class="group-title">
    <i *ngIf="item.icon" [ngClass]="item.icon" class="fa nav-link-icon"></i>
    <span [attr.data-testid]="dataTestId" class="hint-text">{{ item.translate | translate }}</span>
  </div>

  <div class="group-items">
    <ng-container *ngFor="let item of item.children">
      <medis-nav-vertical-group
        *ngIf="item.type === 'group'"
        [item]="item"
        [dataTestId]="'vertical_group_' + item.id"
      ></medis-nav-vertical-group>
      <medis-nav-vertical-item
        *ngIf="item.type === 'item'"
        [item]="item"
        [dataTestId]="'vertical_item_' + item.id"
      ></medis-nav-vertical-item>
    </ng-container>
  </div>
</ng-container>
