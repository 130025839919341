import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-toggle',
  templateUrl: './multi-toggle.component.html',
})
export class MultiToggleComponent {
  @Input() choices;
  @Input() allowDeselect: boolean = false;
  @Input() disabled: boolean = false;
  @Input() value;
  @Input() required;
  @Input() style = '';
  @Output() valueChange = new EventEmitter<any>();

  selectValue(val): void {
    val = this.allowDeselect && val === this.value ? undefined : val;
    if (val !== this.value) {
      this.valueChange.emit((this.value = val));
    }
  }

  isInvalid(): boolean {
    return (
      this.required &&
      (!this.choices || this.choices.findIndex((c) => c.value === this.value) === -1)
    );
  }
}
