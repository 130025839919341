import { gql } from 'apollo-angular';

import {
  MLiterature,
  MMedicalDocument,
  MMedicalDocumentVersion,
  MTherapeuticArea,
} from './medical-document';
import * as MLanguage from '../language/language';
import { MProduct } from '../region/region';
import * as MCountry from '../country/country';
import * as MCategory from '../category/category';
import * as MTopic from '../topic/topic';
import { MTeam } from '../user/user';
import * as MMedicalDocumentType from '../medical-document-type/medical-document-type';
import * as MMedicalDocumentAudience from '../medical-document-audience/medical-document-audience';
import * as MMedicalDocumentVersionFile from '../medical-document-version-file/medical-document-version-file';
import * as MMDWord from '../md-word/md-word';
import * as MMDWordSection from '../md-word-section/md-word-section';

export const retrieveMedDocInformation = gql`
  query getMedicalDocument($id: ID!) {
    medicaldocument(id: $id) {
      ...medicalDocumentFragment
      owner {
        ...teamFragment
      }
      currentVersion {
        ...medicalDocumentVersionFragment
        files {
          ...medicalDocumentVersionFileConnectionFragment
        }
        firstWord {
          ...mdWordFragment
          sections {
            ...mdWordSectionConnectionFragment
          }
        }
      }
      type {
        ...medicalDocumentTypeFragment
      }
      therapeuticAreas {
        ...therapeuticAreaConnectionFragment
      }
      targetAudiences {
        ...medicalDocumentAudienceConnectionFragment
      }
      language {
        ...languageFragment
      }
      products {
        ...productConnectionFragment
      }
      categories {
        ...categoryConnectionFragment
      }
      topics {
        ...topicConnectionFragment
      }
      literatureDetails {
        ...literatureFragment
      }
      usableByCountries {
        ...countryConnectionFragment
      }
    }
  }
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersion.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MMDWord.fragment}
  ${MMDWordSection.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocumentAudience.fragmentConnection}
  ${MLanguage.fragment}
  ${MProduct.fragmentConnection}
  ${MCategory.fragmentConnection}
  ${MTopic.fragmentConnection}
  ${MTeam.fragment}
  ${MLiterature.fragment}
  ${MTherapeuticArea.fragmentConnection}
  ${MCountry.fragmentConnection}
`;

export const createDocumentWithApproval = gql`
  mutation createDocumentWithApproval($params: CreateApprovalProcessInput!) {
    createApprovalProcess(input: $params) {
      medicalDocument {
        ...medicalDocumentFragment
        type {
          ...medicalDocumentTypeFragment
        }
        versions {
          ...medicalDocumentVersionConnectionFragment
        }
      }
    }
  }
  ${MMedicalDocumentVersion.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocument.fragment}
`;

export const createMedicalDocumentVersion = gql`
  mutation createMedicalDocumentVersion($params: CreateMedicalDocumentVersionInput!) {
    createMedicalDocumentVersion(input: $params) {
      medicalDocument {
        ...medicalDocumentFragment
        type {
          ...medicalDocumentTypeFragment
        }
        versions {
          ...medicalDocumentVersionConnectionFragment
        }
      }
    }
  }
  ${MMedicalDocumentVersion.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocument.fragment}
`;
