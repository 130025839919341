import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import {
  BAttachment,
  BInteraction,
  HistoryEditService,
} from 'app/modules/data-model/data-model.module';
import { BInquiry } from 'app/modules/data-model/interaction/interaction';
import { AbstractUploadComponent } from '../upload-attachment/comon-upload-interface';
import { Action, InquiriesService } from 'app/modules/inquiries/services/inquiries.service';
import { TinyMCE } from 'app/common/tiny-mce-options';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

type NoteParams = {
  interactionId: string;
  category: 'other';
  channel: 'email';
  text: string;
  inquiryId?: string;
  linkAttachmentsId?: string;
};
type NoteParamsOnReject = {
  text?: string;
  linkAttachmentsId?: number[];
};

@Component({
  selector: 'app-detail-history-edit',
  templateUrl: './detail-history-edit.component.html',
  styleUrls: ['./detail-history-edit.component.scss'],
})
export class DetailHistoryEditComponent
  extends AbstractUploadComponent
  implements OnInit, OnDestroy
{
  interaction: BInteraction;
  inquiry: BInquiry;
  isNote = false;
  isReject = false;
  isCallCenter = false;
  isSaveBtnDisabled = true;
  editorId = 'detail-history-edit-editor';
  noteText = '';
  attachments: BAttachment[] = [];
  historyChanged = new Subject<NoteParamsOnReject | void>();

  private isSaving = false;
  private notePlainTextMinLength = 15;
  private subs = new Subscription();
  private currentLandingAction: { action: Action; selection: number[]; actionParams: object };

  constructor(
    private editHistoryService: HistoryEditService,
    private dialogRef: MatDialogRef<DetailHistoryEditComponent>,
    private authService: AuthService,
    private inqService: InquiriesService,
    private messageService: MessageHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isSaveBtnDisabled = this.isNote;
    this.subs.add(
      this.editHistoryService.response.subscribe(() => {
        if (this.isSaving) {
          this.isSaving = false;
          this.updateSubjectAndCloseDialog();
        }
      })
    );
    this.subs.add(
      this.authService.user.subscribe((user) => {
        this.isCallCenter = user?.isCallCenter();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  save(): void {
    if (this.isNote && this.isSaveBtnDisabled) {
      this.messageService.info('ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT');
      return;
    }

    if (this.isReject) {
      this.updateSubjectAndCloseDialog();
      return;
    }

    this.isSaving = true;
    if (!this.currentLandingAction) {
      this.saveNote();
      return;
    }
    this.subs.add(
      this.inqService
        .setLandingAction(
          this.currentLandingAction.action,
          this.currentLandingAction.selection,
          this.currentLandingAction.actionParams
        )
        .subscribe(() => {
          this.saveNote();
        })
    );
  }

  onLandingActionPerformed(action: { action: Action; selection: number[]; actionParams: object }) {
    this.currentLandingAction = { ...action };
  }

  reloadIsSaveBtnDisabled(): void {
    this.isSaveBtnDisabled =
      this.isNote &&
      this.attachments.length < 1 &&
      !TinyMCE.validateEditorPlainTextLength(this.editorId, this.notePlainTextMinLength);
  }

  handleAttachmentUpload(attachment: BAttachment): void {
    this.attachments.push(attachment);
    this.reloadIsSaveBtnDisabled();
  }

  handleAttachmentDeletion(attachmentId: string): void {
    this.attachments = this.attachments.filter((attachment) => attachment.id !== attachmentId);
    this.reloadIsSaveBtnDisabled();
  }

  private getNoteParams(): NoteParams {
    const noteParams: NoteParams = {
      interactionId: this.interaction.id,
      category: 'other',
      channel: 'email',
      text: this.noteText,
    };
    if (this.inquiry?.id) {
      noteParams.inquiryId = this.inquiry.id;
    }
    if (this.attachments.length) {
      noteParams.linkAttachmentsId = JSON.stringify(this.attachments.map((a) => a.id));
    }
    return noteParams;
  }

  private getNoteParamsOnReject(): NoteParamsOnReject {
    const noteParamsOnReject: NoteParamsOnReject = {};
    if (TinyMCE.validateEditorPlainTextLength(this.editorId, 1)) {
      noteParamsOnReject.text = this.noteText;
    }
    if (this.attachments.length) {
      noteParamsOnReject.linkAttachmentsId = this.attachments.map((a) => a.pk());
    }
    return noteParamsOnReject;
  }

  private saveNote(): void {
    this.editHistoryService.successMessage = 'NOTE_PROPERLY_INSERTED_IN_HISTORY';
    this.editHistoryService.saveHistoryWithData(this.getNoteParams());
  }

  private hasRequiredDataForRejectNote(): boolean {
    const hasPlainText = TinyMCE.validateEditorPlainTextLength(this.editorId, 1);
    const hasImage = this.noteText?.includes('<img');
    const hasAttachment = this.attachments.length > 0;
    return hasPlainText || hasImage || hasAttachment;
  }

  private updateSubjectAndCloseDialog(): void {
    if (this.isReject && this.hasRequiredDataForRejectNote()) {
      this.historyChanged.next(this.getNoteParamsOnReject());
    } else {
      this.historyChanged.next();
    }
    this.dialogRef.close();
  }
}
