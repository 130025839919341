import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';

import {
  BMedicalDocument,
  BTherapeuticArea,
  DocumentCreationData,
} from 'app/modules/data-model/medical-document/medical-document';
import {
  BCountry,
  BTeam,
  BTopic,
  BUser,
  MedicalDocumentService,
  NomenclaturesService,
  Scopes,
  SingleMedicalDocumentService,
} from 'app/modules/data-model/data-model.module';
import { BMedicalDocumentType } from 'app/modules/data-model/medical-document-type/medical-document-type';
import { BMedicalDocumentAudience } from 'app/modules/data-model/medical-document-audience/medical-document-audience';
import {
  Config,
  newCategoryConfig,
  newCountryConfig,
  newLanguageConfig,
  newProductConfig,
  newTeamConfig,
  newTherapeuticAreaConfig,
  newTopicConfig,
} from 'app/common/common/search-select/search-select.config';
import { AuthService } from 'app/auth/auth.service';
import { DocumentsApprovalWorkflowComponent } from 'app/modules/administration/team-settings/documents-approval/documents-approval-workflow/documents-approval-workflow.component';
import { EDialogStatuses } from 'app/common/common/enums/dialog.enum';
import { MedicalDocumentsApprovalService } from 'app/modules/data-model/medical-documents-approval/medical-documents-approval.service';
import { environment } from 'app/../environments/environment';
import { EView } from '@mi-tool/enums';
import { TDocumentsApprovalWorkflowDialogResult } from 'app/modules/data-model/medical-documents-approval/medical-documents-approval';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { omit } from 'lodash';
import {
  DIALOG_STATUSES_DRAFT_AND_SUBMIT_FOR_APPROVAL,
  STATUSES_MAP,
} from 'app/common/common/constants/statuses';

@Component({
  selector: 'app-document-create',
  templateUrl: './document-create.component.html',
  styleUrls: ['./document-create.component.scss'],
})
export class DocumentCreateComponent implements OnInit, OnDestroy {
  // these are used to simplify data handling during creation, we can dump directly this info and send to the server
  documentData: DocumentCreationData;
  // just a comodity to preserve info descriptions in one place
  document: BMedicalDocument;
  createVersionMode: boolean = false;
  localizationMode: boolean = false;

  types: BMedicalDocumentType[];
  availableTypeCodes: string[] = ['srd', 'smpc', 'faq', 'pil', 'slide', 'lit'];

  teamConfig: Config = newTeamConfig([]);
  countryConfig: Config = newCountryConfig([]);
  therapeuticAreaConfig: Config = newTherapeuticAreaConfig([]);
  languageConfig: Config = newLanguageConfig([]);
  productConfig: Config = newProductConfig([]);
  categoryConfig: Config = newCategoryConfig([]);
  topicConfig: Config = newTopicConfig([]);

  audiences: BMedicalDocumentAudience[] = [];
  autoApproveDocument = true;
  prePopulatedProps = ['scheduledExpirationDate', 'scheduledPublishingDate', 'versionNumber'];

  private subs: Subscription = new Subscription();
  private topics: BTopic[] = [];
  private allTeams: BTeam[];
  private allTherapeuticAreas: BTherapeuticArea[];
  private allCountries: BCountry[];
  private languageIdEnglish: string;
  private loggedInUser: BUser;

  private changeLanguageByDefault: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DocumentCreateComponent>,
    private editMedicalDocumentService: MedicalDocumentService,
    private singleMedicalDocumentService: SingleMedicalDocumentService,
    private authService: AuthService,
    private nomenclaturesService: NomenclaturesService,
    private matDialog: MatDialog,
    private mdApprovalService: MedicalDocumentsApprovalService
  ) {
    this.documentData = new DocumentCreationData();
    this.document = new BMedicalDocument({});
  }

  ngOnInit() {
    this.subs.add(
      this.editMedicalDocumentService.createResponse.subscribe((document) => {
        if (document) {
          // After upload show document view only if the document does not require approval.
          if (this.autoApproveDocument) {
            this.singleMedicalDocumentService.performQuery(document.id);
          }
          this.dialogRef.close();
        }
      })
    );
    this.subs.add(
      this.authService.user.subscribe((user) => {
        this.loggedInUser = user;
        this.productConfig = newProductConfig(user.defaultListProducts);
        this.teamConfig = newTeamConfig(user.documentTeams);

        if (!this.documentData.ownerId && this.teamConfig.rawItems.length === 1) {
          this.documentData.ownerId = this.teamConfig.rawItems[0].id;
        }
      })
    );
    const scopes = new Scopes()
      .countries()
      .allActiveTherapeuticAreas()
      .languages()
      .activeCategories()
      .activeTopics()
      .medicalDocumentAudiences()
      .medicalDocumentTypes()
      .teamsExtendedData();
    this.subs.add(
      this.nomenclaturesService.get(scopes).subscribe((resp) => {
        this.countryConfig = newCountryConfig(resp.countries);
        this.therapeuticAreaConfig = newTherapeuticAreaConfig(resp.therapeuticAreas);
        this.languageConfig = newLanguageConfig(resp.languages);
        this.categoryConfig = newCategoryConfig(resp.categories);
        this.topics = resp.topics;
        this.audiences = resp.medicalDocumentAudiences;
        this.types = resp.medicalDocumentTypes;
        if (this.documentData.typeId && this.document) {
          this.document.type = this.types.filter((x) => x.id == this.documentData.typeId)[0];
        }
        this.allTeams = resp.teams;
        this.allTherapeuticAreas = resp.therapeuticAreas;
        this.allCountries = resp.countries;
        this.languageIdEnglish = resp.languages.find((l) => l.code === 'en').id;
        this.setCategories(this.documentData.categoriesId);
        this.setTeam(this.documentData.ownerId);
        if (this.documentData.targetAudiencesId && this.documentData.targetAudiencesId.length) {
          this.audiences.forEach((a) => {
            a.selected = this.documentData.targetAudiencesId.includes(a.id);
          });
        }
      })
    );

    if (this.documentData.languageId != undefined) {
      this.changeLanguageByDefault = false;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  canSave(): boolean {
    return (
      this.documentData.file != undefined &&
      this.documentData.typeId != undefined &&
      this.documentData.ownerId != undefined &&
      this.documentData.therapeuticAreaId != undefined &&
      this.documentData.productsId != undefined &&
      this.documentData.productsId.length > 0 &&
      this.documentData.categoriesId != undefined &&
      this.documentData.categoriesId.length > 0 &&
      this.documentData.topicsId != undefined &&
      this.documentData.topicsId.length > 0 &&
      this.documentData.countriesId != undefined &&
      this.documentData.countriesId.length > 0 &&
      (!this.document.isBinaryFile() || this.documentData.shortTitle != undefined)
    );
  }

  handleDocumentCreate(): void {
    if (this.autoApproveDocument) {
      this.saveVersion();
      return;
    }
    this.openDocumentApprovalWorkflowDialog();
  }

  saveVersion(workflowDialogData: TDocumentsApprovalWorkflowDialogResult = null): void {
    let processData = {};
    let documentData = { ...this.documentData.serialize() };

    if (workflowDialogData) {
      processData['approvalWorkflow'] = JSON.stringify(workflowDialogData.approvalWorkflow);
      documentData['status'] =
        workflowDialogData.status === EDialogStatuses.CREATE_DRAFT
          ? STATUSES_MAP.DRAFT.code
          : STATUSES_MAP.SUBMITTED_FOR_APPROVAL.code;
    }

    processData['document'] = JSON.stringify(documentData);

    if (this.createVersionMode || this.localizationMode) {
      processData['id'] = this.document.id;
      processData['isLocalization'] = this.localizationMode;
      this.editMedicalDocumentService.createVersion(processData);
    } else {
      this.editMedicalDocumentService.createDocumentWithApproval(processData);
    }
  }

  private openDocumentApprovalWorkflowDialog(): void {
    const selectedTeam = this.teamConfig.rawItems.find(
      (team) => team.id === this.documentData.ownerId
    );
    if (!selectedTeam || !this.loggedInUser) {
      throw new Error('Missing data for workflow dialog!');
    }

    const dialogRef = this.matDialog.open(DocumentsApprovalWorkflowComponent, {
      disableClose: true,
      maxHeight: '95vh',
      maxWidth: '95vw',
    });
    dialogRef.componentInstance.view = EView.DOCUMENT;
    dialogRef.componentInstance.documentAuthor = this.loggedInUser;
    dialogRef.componentInstance.team = selectedTeam;
    dialogRef.afterClosed().subscribe((result: TDocumentsApprovalWorkflowDialogResult) => {
      if (result.status === EDialogStatuses.ABORT_FILE_UPLOAD) {
        this.dialogRef.close();
      } else if (DIALOG_STATUSES_DRAFT_AND_SUBMIT_FOR_APPROVAL.includes(result.status)) {
        this.saveVersion(result);
      }
    });
  }

  createNewVersion(document: BMedicalDocument): void {
    this.document = document;
    this.createVersionMode = true;
    this.documentData.setFromDocument(document);
    if (this.document.currentVersion) {
      this.documentData.versionNumber = this.document.currentVersion.versionNumber + 1;
    }
    this.availableTypeCodes = [document.type.code];
    this.setTeam(this.documentData.ownerId);
  }

  finalizeLocalization(document: BMedicalDocument) {
    this.document = document;
    this.localizationMode = true;
    this.documentData.setFromDocument(document);
    this.setTeam(this.documentData.ownerId);
  }

  setType(code: string): void {
    this.document.type = this.types.filter((x) => x.code === code)[0];
    this.documentData.typeId = this.document.type.id;
    this.checkIfDocumentShouldBeApproved();
  }

  setTeam(id: string): void {
    if (this.allTeams && this.allTherapeuticAreas && id) {
      const team = this.allTeams.find((t) => t.id === id);
      if (team) {
        const teamTherapeuticAreaNames = team.productGroups.map((pg) => pg.therapeuticArea);
        const therapeuticAreas = teamTherapeuticAreaNames.length
          ? this.allTherapeuticAreas.filter((t) => teamTherapeuticAreaNames.includes(t.name))
          : this.allTherapeuticAreas;
        this.therapeuticAreaConfig = newTherapeuticAreaConfig(therapeuticAreas);
        if (this.therapeuticAreaConfig.rawItems.length === 1) {
          this.documentData.therapeuticAreaId = this.therapeuticAreaConfig.rawItems[0].id;
        }
        const countries =
          team.countries && team.countries.length ? team.countries : this.allCountries;
        this.countryConfig = newCountryConfig(countries);
        this.documentData.countriesId =
          this.countryConfig.rawItems.length === 1
            ? [this.countryConfig.rawItems[0].id]
            : (this.documentData.countriesId || []).filter((countryId) =>
                countries.some((country) => country.id === countryId)
              );
        this.changeCountry(this.documentData.countriesId);
        // If the selected team has more than one country try to set default country based on user affiliations.
        this.setDefaultCountry();
        this.checkIfDocumentShouldBeApproved();
      }
    }
  }

  setCategories(categoryIds: string[]): void {
    if (!categoryIds || !categoryIds.length) {
      this.topicConfig = newTopicConfig([]);
      this.documentData.topicsId = [];
    } else {
      const filteredTopics = this.topics.filter((topic) => categoryIds.includes(topic.categoryId));
      const filteredTopicIds = filteredTopics.map((topic) => topic.id);
      this.topicConfig = newTopicConfig(filteredTopics);
      this.documentData.topicsId = this.documentData.topicsId.filter((topicId) =>
        filteredTopicIds.includes(topicId)
      );
    }
  }

  triggerAudience(): void {
    this.documentData.targetAudiencesId = this.audiences
      .filter((a) => a['selected'])
      .map((x) => x.id);
  }

  checkIfDocumentShouldBeApproved(): void {
    this.autoApproveDocument = true;
    if (!environment.hideWorkInProgress && this.documentData.ownerId && this.document.type) {
      const selectedTeamId = this.teamConfig.rawItems
        .find((team) => team.id === this.documentData.ownerId)
        .pk();
      combineLatest([
        this.mdApprovalService.teamConfigFetch(selectedTeamId),
        this.mdApprovalService.autoApprovalDocumentTypesFetch(selectedTeamId),
      ]).subscribe(([teamData, autoApprovalDocumentTypeIds]) => {
        if (
          teamData.documentApprovalWithinEnqmed &&
          !autoApprovalDocumentTypeIds.includes(this.document.type.pk())
        ) {
          this.autoApproveDocument = false;
          !teamData.hasWorkflowTemplates && this.openApprovalWorkflowsMsg();
        }
      });
    }
  }

  setDefaultCountry(): void {
    if (this.loggedInUser && this.documentData.countriesId?.length === 0) {
      /* Resolve country id based on the user affiliations */
      const countryBasedOnAffiliations = this.loggedInUser.resolveCountryBasedOnAffiliations(
        this.countryConfig.rawItems
      );
      if (countryBasedOnAffiliations != undefined) {
        this.documentData.countriesId = [countryBasedOnAffiliations.id];
        this.changeCountry(this.documentData.countriesId);
      }
    }
  }

  changeCountry(countryId: string[]): void {
    // set default language
    if (this.changeLanguageByDefault) {
      if (countryId.length === 0) {
        this.documentData.languageId = '';
      } else {
        const defLangIds = this.allCountries
          .filter((country) => countryId.includes(country.id))
          .map((c) => c.defaultLanguage.id);
        const langId = new Set(defLangIds).size === 1 ? defLangIds[0] : this.languageIdEnglish;
        this.documentData.languageId = langId;
      }
    }
  }

  changeLanguage(langId: string): void {
    this.changeLanguageByDefault = langId == undefined;
  }

  handleDialogClosing(): void {
    const hasDocumentData = Object.values(omit(this.documentData, this.prePopulatedProps)).some(
      (documentPropValue) =>
        Array.isArray(documentPropValue) ? documentPropValue.length : Boolean(documentPropValue)
    );
    if (!hasDocumentData) {
      this.dialogRef.close();
      return;
    }
    const dialogRef = GenericDialogComponent.openConfirmationMsg(
      this.matDialog,
      'DOCUMENT_CREATE_CONFIRM_CLOSE_MSG',
      'DO_YOU_WANT_TO_PROCEED'
    );
    dialogRef.componentInstance.response.subscribe((response) => {
      dialogRef.close();
      response && this.dialogRef.close();
    });
  }

  private openApprovalWorkflowsMsg(): void {
    const dialogRef = GenericDialogComponent.openApprovalWorkflowsMsg(this.matDialog);
    dialogRef.componentInstance.response.subscribe(() => {
      dialogRef.close();
    });
  }
}
