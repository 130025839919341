export class BDMMessageAttachment {
  id: number;
  name: string;

  static fromRest(object: any): BDMMessageAttachment {
    return object && Object.assign(new BDMMessageAttachment(), object);
  }

  static fromRestArray(arrayOfObjects: any[]): BDMMessageAttachment[] {
    return arrayOfObjects && arrayOfObjects.map((object) => BDMMessageAttachment.fromRest(object));
  }
}

export class BDMMessageType {
  id: number;
  name: string;

  static fromRest(object: any): BDMMessageType {
    return object && Object.assign(new BDMMessageType(), object);
  }

  static fromRestArray(arrayOfObjects: any[]): BDMMessageType[] {
    return arrayOfObjects && arrayOfObjects.map((object) => BDMMessageType.fromRest(object));
  }
}

export class BDMMessage {
  id: number;
  subject: string;
  fromHeader: string;
  toHeader: string;
  outgoing: boolean;
  processed: string;
  inReplyToId: number;
  mailboxId: number;
  typeId: number;
  refId: number;
  eml: string;
  attachments: BDMMessageAttachment[];

  html: string;
  decodedMsg: string;
  mailboxName: string;
  typeName: string;

  static fromRest(object: any): BDMMessage {
    if (object) {
      object.attachments = BDMMessageAttachment.fromRestArray(object.attachments) || [];
      return Object.assign(new BDMMessage(), object);
    }
    return object;
  }

  static fromRestArray(arrayOfObjects: any[]): BDMMessage[] {
    return arrayOfObjects && arrayOfObjects.map((object) => BDMMessage.fromRest(object));
  }
}
