import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { isNil, omitBy } from 'lodash';
import { IAuthFilters } from '@mi-tool/models/auth-model';
import { EAuthStatus, EAuthTitle, EAuthType } from '@mi-tool/enums';
import { apiUrl } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { AuthRequestModel, UserRequestModel } from './models/auth-request.model';
import { AuthRequestNewUserModel } from './models/auth-request-new-user.model';

@Injectable({ providedIn: 'root' })
export class AuthRequestsService {
  private typesWithRoles = {
    [EAuthType.NewUser]: null,
    [EAuthType.NewCategoryTopic]: ['sys_admin', 'user_admin'],
    [EAuthType.NewUserUpdate]: null,
  };

  selectedFilters = new BehaviorSubject<IAuthFilters>(IAuthFilters.defaults());
  resetFilters: Subject<IAuthFilters> = new Subject<IAuthFilters>();

  private readonly REGISTRATION_URL = apiUrl('users', 'registrations');
  private readonly REGISTRATION_APPROVALS_URL = apiUrl('users', 'registrations', 'approvals');
  private readonly NEW_USERS_AUTH_REQUESTS_URL = apiUrl('users', 'auth-requests');

  constructor(private http: HttpClient) {}

  get(
    filters: IAuthFilters,
    pageSize: number,
    page: number,
    orderBy: string | null
  ): Observable<SimplePage<AuthRequestModel>> {
    const query = prepareQuery(this.REGISTRATION_APPROVALS_URL, filters, pageSize, page, orderBy);
    return this.http.get<SimplePage<AuthRequestModel>>(query);
  }

  update(request: AuthRequestUpdate): Observable<string> {
    return this.http.post<string>(this.REGISTRATION_APPROVALS_URL, request);
  }

  getSingleRequestData(
    requestId: string,
    authRequestId: string | null = null
  ): Observable<AuthRequestNewUserModel> {
    return this.http.get<AuthRequestNewUserModel>(`${this.REGISTRATION_URL}/${requestId}`, {
      params: omitBy(
        {
          authRequestId,
        },
        isNil
      ),
    });
  }

  getUserRequestsData(
    status: string,
    pageNumber = 1,
    pageSize = 10,
    filters: Record<string, any>,
    orderBy: string | null
  ): Observable<SimplePage<UserRequestModel>> {
    let queryParams = omitBy<any>({ status, pageSize, pageNumber, orderBy, ...filters }, isNil);
    Object.keys(queryParams).forEach((filterKey) => {
      if (['dateRange', 'approvedDate'].includes(filterKey)) {
        if (queryParams[filterKey].from && queryParams[filterKey].to) {
          queryParams[filterKey] = `${queryParams[filterKey].from},${queryParams[filterKey].to}`;
        } else {
          delete queryParams[filterKey];
        }
      }
    });
    return this.http.get<SimplePage<UserRequestModel>>(`${this.NEW_USERS_AUTH_REQUESTS_URL}`, {
      params: queryParams,
    });
  }

  getFilterTypesBasedOnRoles(userRoles: string[]): string[] {
    const result = [];

    Object.keys(EAuthType)
      .filter(
        (typeKey) =>
          !this.typesWithRoles[typeKey] ||
          this.typesWithRoles[typeKey].filter((r) => userRoles.includes(r)).length
      )
      .forEach((key) => result.push(EAuthType[key]));

    return result;
  }
}

export enum Status {
  PENDING_EMAIL_CONFIRMATION = 1,
  PENDING_REVIEW = 2,
  APPROVED = 3,
  REJECTED = 4,
}

export enum Title {
  CategoryTopic = 'CategoryTopic',
  User = 'User',
  UserUpdate = 'User Update',
}

export namespace Title {
  export function from(val: string | string[]): Title[] {
    if (!val) {
      return [];
    }

    const valArr = typeof val === 'string' ? [val] : val;

    return valArr.map((v) => {
      const title = EAuthTitle[v];

      switch (title) {
        case EAuthTitle.CategoryTopicRequest:
          return Title.CategoryTopic;

        case EAuthTitle.UserRequest:
          return Title.User; // confirm User or UserUpdate

        default:
          throw 'Unsupported from title: ' + v;
      }
    });
  }

  export function to(val: string): EAuthTitle {
    if (!val) {
      return undefined;
    }

    const title = Title[val];

    switch (title) {
      case Title.User:
        return EAuthTitle.UserRequest;

      case Title.CategoryTopic:
        return EAuthTitle.CategoryTopicRequest;

      default:
        throw 'Unsupported to title: ' + val;
    }
  }
}

export namespace Status {
  export function from(val: string) {
    if (!val) {
      return undefined;
    }
    const status = EAuthStatus[val];
    switch (status) {
      case EAuthStatus.Open:
        return `${Status.PENDING_EMAIL_CONFIRMATION},${Status.PENDING_REVIEW}`;
      case EAuthStatus.Approved:
        return Status.APPROVED;
      case EAuthStatus.Rejected:
        return Status.REJECTED;
      default:
        throw 'Unsupported from status: ' + val;
    }
  }

  export function to(val: string): EAuthStatus {
    if (!val) {
      return undefined;
    }
    const status = Status[val];
    switch (status) {
      case Status.APPROVED:
        return EAuthStatus.Approved;
      case Status.REJECTED:
        return EAuthStatus.Rejected;
      case Status.PENDING_REVIEW:
      case Status.PENDING_EMAIL_CONFIRMATION:
        return undefined;
      default:
        throw 'Unsupported to status: ' + val;
    }
  }
}

export enum Type {
  USER_REGISTRATION = 1,
  TOPIC_CATEGORY_SUGGESTION = 2,
  USER_UPDATE_SUGGESTION = 3,
}

export namespace Type {
  export function from(val: string | string[]): Type[] {
    if (!val) {
      return undefined;
    }
    const valArr = typeof val === 'string' ? [val] : val;
    return valArr.map((v) => {
      const type = EAuthType[v];
      switch (type) {
        case EAuthType.NewUser:
          return Type.USER_REGISTRATION;
        case EAuthType.NewCategoryTopic:
          return Type.TOPIC_CATEGORY_SUGGESTION;
        case EAuthType.NewUserUpdate:
          return Type.USER_UPDATE_SUGGESTION;
        default:
          throw 'Unsupported from type: ' + v;
      }
    });
  }

  export function to(val: string): EAuthType {
    if (!val) {
      return undefined;
    }
    const title = Type[val];
    switch (title) {
      case Type.USER_REGISTRATION:
        return EAuthType.NewUser;
      case Type.TOPIC_CATEGORY_SUGGESTION:
        return EAuthType.NewCategoryTopic;
      case Type.USER_UPDATE_SUGGESTION:
        return EAuthType.NewUserUpdate;
      default:
        throw 'Unsupported to type: ' + val;
    }
  }
}

export type AuthRequestUpdate = {
  id: number;
  status: string;
  category?: string;
  topic?: string;
  products?: number[];
  roleId?: string;
  title?: string;
  assignedTo?: string;
  countryCode?: string;
  company?: string;
  therapeuticArea?: string;
  department?: string;
  sendEmail: boolean;
  reviewComment?: string;
};

function prepareQuery(
  URL: string,
  filters: IAuthFilters,
  pageSize: number,
  page: number,
  orderBy: string | null
): string {
  let query = URL + '?';
  query += resolveFilterParam('page_size', pageSize);
  query += resolveFilterParam('page', page);
  query += resolveFilterParam('user_id', filters.requestedBy);
  query += resolveFilterParam('status', Status.from(filters.status));
  query += filters.id ? resolveFilterParam('id', filters.id) : '';
  query += resolveMultiFilterParam('type', Type.from(filters.type));
  query += resolveMultiFilterParam('handled_by_id', filters.assignedTo);
  query += resolveDateFilterParam(
    'created_ts',
    filters.dateRange && filters.dateRange.from,
    filters.dateRange && filters.dateRange.to
  );
  query += resolveDateFilterParam(
    'handled_ts',
    filters.approvedDate && filters.approvedDate.from,
    filters.approvedDate && filters.approvedDate.to
  );
  query += resolveFilterParam('only_open', filters.onlyOpen ? 1 : undefined);
  // query += resolveFilterParam('my_team', filters.myTeam ? 1 : undefined); // Category/ topic requests are not team specific, hence we do not need this filter
  query += orderBy ? `&order_by=${orderBy}` : '';
  return query;
}

function resolveFilterParam(name: string, value: string | number): string {
  return value || value === 0 ? `&${name}=${value}` : '';
}

function resolveMultiFilterParam(name: string, value: any | any[]): string {
  const strValue = typeof value === 'string' ? value : value && value.join(',');
  return resolveFilterParam(name, strValue);
}

function resolveDateFilterParam(name: string, fromDt: string | Date, toDt: string | Date): string {
  if (!fromDt || !toDt) {
    return '';
  }

  const valFrom = fromDt instanceof Date ? fromDt.toISOString() : fromDt;
  const valTo = toDt instanceof Date ? toDt.toISOString() : toDt;
  return resolveFilterParam(name, `${valFrom},${valTo}`);
}
