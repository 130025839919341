import { PermissionHandler } from 'app/auth/auth.service';
import { MedisNavigationItem, filter, group, newItem } from '../types';
import {
  PERM_MANAGE_OWN_USERS,
  PERM_VIEW_USER_ADMIN,
} from 'app/common/common/constants/permissions';

const authRequestNav: MedisNavigationItem[] = [
  group(
    'All_teams',
    'ALL_TEAMS',
    '',
    [
      newItem(
        'ALL_REQUESTS_ALL_TEAMS',
        'ALL_REQUESTS_ALL_TEAMS',
        '',
        '/administration/auth-requests',
        'all_teams',
        undefined,
        { reqType: 'all' }
      ),
    ],
    [PERM_VIEW_USER_ADMIN]
  ),
  group(
    'USER_REQUESTS',
    'USER_REQUESTS',
    undefined,
    [
      newItem(
        'OPEN_REQUESTS',
        'OPEN_REQUESTS',
        '',
        '/administration/auth-requests/',
        'my_team',
        undefined,
        { reqType: 'user', status: 'open' }
      ),
      newItem(
        'APPROVED_REQUESTS',
        'APPROVED_REQUESTS',
        'fa-user-friends',
        '/administration/auth-requests/',
        null,
        undefined,
        { reqType: 'user', status: 'approved' }
      ),
      newItem(
        'REJECTED_REQUESTS',
        'REJECTED_REQUESTS',
        'fa-reply',
        '/administration/auth-requests/',
        null,
        undefined,
        { reqType: 'user', status: 'rejected' }
      ),
    ],
    [PERM_MANAGE_OWN_USERS, PERM_VIEW_USER_ADMIN]
  ),
  group(
    'CATEGORY_TOPIC_REQUESTS',
    'CATEGORY_TOPIC_REQUESTS',
    undefined,
    [
      newItem(
        'MY_TEAM_OPEN_REQUESTS',
        'MY_TEAM_OPEN_REQUESTS',
        '',
        '/administration/auth-requests/',
        'my_team',
        undefined,
        { reqType: 'auth', status: 'open' }
      ),
      newItem(
        'MY_TEAM_APPROVED_REQUESTS',
        'MY_TEAM_APPROVED_REQUESTS',
        'fa-user-friends',
        '/administration/auth-requests/',
        null,
        undefined,
        { reqType: 'auth', status: 'approved' }
      ),
      newItem(
        'MY_TEAM_REJECTED_REQUESTS',
        'MY_TEAM_REJECTED_REQUESTS',
        'fa-reply',
        '/administration/auth-requests/',
        null,
        undefined,
        { reqType: 'auth', status: 'rejected' }
      ),
    ],
    [PERM_VIEW_USER_ADMIN]
  ),
];

export function authRequestNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  return filter(authRequestNav, permissions);
}
