import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Subscription } from 'rxjs';
import { snakeCase } from 'lodash';
import {
  Config,
  newAuditActionList,
  newColumnsConfig,
  newUserConfig,
} from 'app/common/common/search-select/search-select.config';
import {
  BInquirer,
  BUser,
  Nomenclatures,
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/data-model.module';
import { UserPreferencesService } from 'app/common/common/user-preferences.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { Helpers } from '@mi-tool/utils/helpers';
import { AuditTrailService } from 'app/modules/data-model/audit-trail/audit-trail.service';
import {
  BAuditTrail,
  BAuditTrailRecord,
  TAuditTrailDetailData,
} from 'app/modules/data-model/audit-trail/audit-trail';
import { TranslateService } from '@ngx-translate/core';
import { EAuditTrailDetailData, ESort } from '@mi-tool/enums';
import { AuditTrailPreviewDialogComponent } from './audit-trail-preview-details/audit-trail-preview-dialog.component';
import {
  AuditDataType,
  DATA_TYPE_TRANSLATIONS_MAPPER,
  NonUserAuditData,
  NonUserSingleAuditData,
  UserAuditData,
} from './audit-trail-data';
import { NgbdModalUser } from 'app/common/common/modal/modal-user/modal-user.component';
import { DateRange } from 'app/common/types';
import { DateUtility } from 'app/common/date-utility';
import { downloadExcel } from 'app/common/util/dom';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss'],
})
export class AuditTrailComponent implements OnInit, OnDestroy {
  auditList: Array<BAuditTrailRecord> = [];
  actionUserId: string = null;
  userConfig = newUserConfig([]);
  titleDataType: string = 'All';
  changedUserID: string = null;
  userAuditId: string = null;
  auditDataType: AuditDataType = 'user';
  auditDataId: string = null;
  columnsToExclude: string[] = [];
  selectedUser: BUser = null;
  auditActionsListConfig = newAuditActionList([]);
  auditColumnsList: Nomenclatures['auditColumnsList'] = null;
  selectedActions: string[] = null;
  formattedEventDatetime: string = null;
  columnsConfig: Config = newColumnsConfig([]);
  displayedColumns = [
    'id',
    'eventDatetime',
    'actionUser',
    'changedUser',
    'action',
    'originalValue',
    'newValue',
  ];
  selectedColumns: string[] = [];
  pageCount = 0;
  pageSize = 10;
  pageIndex = 0;
  searchedId: string = null;
  searchedOriginalValue: string = null;
  searchedNewValue: string = null;
  orderBy: string = null;
  appliedFilters$ = new BehaviorSubject(null);
  loading = true;
  private subs = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: UserAuditData | NonUserAuditData | NonUserSingleAuditData,
    private modalService: MatDialog,
    private helpers: Helpers,
    private nomenclatures: NomenclaturesService,
    private auditTrailService: AuditTrailService,
    private userPreferencesService: UserPreferencesService,
    private translateService: TranslateService,
    private messageService: MessageHandlerService
  ) {
    if (this.data instanceof UserAuditData) {
      this.userAuditId = this.data.allUsers.length === 1 ? this.data.allUsers[0].id : null;
      this.selectedUser = this.data.allUsers.length === 1 ? this.data.allUsers[0] : null;
    } else if (this.data instanceof NonUserAuditData) {
      this.auditDataId = this.data.dataValue ? this.data.dataValue.id?.toString() : null;
      this.auditDataType = this.data.dataType;
      this.columnsToExclude = ['changedUser', 'changedInquirer'];
    } else {
      this.auditDataId = null;
      this.auditDataType = this.data.dataType;
      this.columnsToExclude = ['changedUser', 'changedInquirer'];
    }
    this.titleDataType = this.resolveDataTitle();
    this.appliedFilters$.next(this.getFilters());
  }

  ngOnInit(): void {
    const scopes = new Scopes()
      .auditActionsList(this.auditDataType)
      .auditColumnsList()
      .usersBasicInfo();
    this.nomenclatures.get(scopes).subscribe((resp: Nomenclatures) => {
      this.auditActionsListConfig = newAuditActionList(resp.auditActionsList);
      this.auditColumnsList = resp.auditColumnsList;
      this.columnsConfig = newColumnsConfig(resp.auditColumnsList);
      this.userConfig = newUserConfig(resp.users);
      this.displayedColumns = resp.auditColumnsList
        .filter((c) => c.displayable && !this.columnsToExclude.includes(c.name))
        .map((c) => c.name);
      this.selectedColumns = resp.auditColumnsList.filter((f) => f.checked).map((c) => c.name);
    });
    this.subs.add(
      this.appliedFilters$
        .pipe(
          tap(() => (this.loading = true)),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(() => this.fetchAuditTrailList())
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onColumnsChange(): void {
    this.userPreferencesService
      .set(
        'AUDIT_COLUMNS_LIST',
        JSON.stringify(
          this.auditColumnsList.map((column) => ({
            ...column,
            checked: this.selectedColumns.includes(column.name),
          }))
        )
      )
      .subscribe();
  }

  onUserChange(event: string): void {
    if (this.data instanceof UserAuditData) {
      this.selectedUser = this.data.allUsers.find((u) => u.id === event);
      this.titleDataType = this.resolveDataTitle();
    }
    this.triggerSearch();
  }

  onDateRangeChange(event: DateRange): void {
    const newDateRange = DateUtility.formatDateRange(event);
    if (newDateRange !== this.formattedEventDatetime) {
      this.formattedEventDatetime = newDateRange;
      this.triggerSearch();
    }
  }

  viewUserInfo(user: BUser, event: MouseEvent): void {
    event.stopPropagation();
    this.modalService.open(NgbdModalUser, { data: { userId: user.pk() } });
  }

  viewInquirerInfo(inquirer: BInquirer, event: MouseEvent): void {
    event.stopPropagation();
    this.modalService.open(NgbdModalUser, { data: { inquirerId: inquirer.pk() } });
  }

  exportColumns(): void {
    const params = {
      export: 'excel',
    };
    this.appliedFilters$.next({ ...this.getFilters(), ...params });
  }

  goToPage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.triggerSearch();
  }

  onSortChange(column: Sort): void {
    this.orderBy = column.direction
      ? (column.direction == 'desc' ? ESort.DESC : ESort.ASC) + snakeCase(column.active)
      : null;
    this.triggerSearch();
  }

  triggerSearch(): void {
    this.appliedFilters$.next({ ...this.getFilters() });
  }

  handleAudiTrailPreview(record: BAuditTrailRecord): void {
    this.auditTrailService.getDetailsFor(record.id.toString()).subscribe({
      next: (response) => {
        if (record.type === EAuditTrailDetailData.DOC_DIFF_EXTERNAL) {
          window.open(response.data, '_blank');
          return;
        }
        this.openAuditTrailPreviewDialog(response);
      },
      error: () => {
        this.messageService.error('An error occurred while fetching the data for the preview.');
      },
    });
  }

  private openAuditTrailPreviewDialog(dialogData: TAuditTrailDetailData): void {
    this.modalService.open(AuditTrailPreviewDialogComponent, {
      data: {
        htmlChanges: dialogData.data,
        originalDetailValue: dialogData.originalDetailValue,
        newDetailValue: dialogData.newDetailValue,
      },
    });
  }

  private fetchAuditTrailList(options = { params: {} }): void {
    const currentParams = this.appliedFilters$.getValue();
    const params = { ...currentParams, ...options.params };

    const reqOptions = {
      params,
      responseType: 'export' in currentParams ? ('blob' as 'json') : null,
    };
    this.auditTrailService
      .get(reqOptions)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((resp: BAuditTrail) => {
        if (resp instanceof Blob) {
          downloadExcel(resp, `audit-trail-${this.titleDataType}`);
          return;
        }
        this.auditList = resp.records.map((auditTrailRecord) => {
          if (auditTrailRecord.actionUser) {
            auditTrailRecord.actionUser = BUser.fromRest(auditTrailRecord.actionUser);
          }
          if (auditTrailRecord.changedUser) {
            auditTrailRecord.changedUser = BUser.fromRest(auditTrailRecord.changedUser);
          } else if (auditTrailRecord.changedInquirer) {
            auditTrailRecord.changedInquirer = BInquirer.fromRest(auditTrailRecord.changedInquirer);
          }
          return auditTrailRecord;
        });
        this.pageCount = resp.totalRecords;
        this.helpers.scrollToTop();
      });
  }

  private getFilters() {
    const isNoTeamRoleSelected =
      this.data instanceof UserAuditData ? this.data?.role === 'no_team' : null;

    return {
      actionUser: this.actionUserId,
      changedUser: this.changedUserID,
      userAuditId: this.userAuditId,
      auditDataType: this.auditDataType,
      auditDataId: this.auditDataId,
      action: this.helpers.arrayToStringOrNull(this.selectedActions),
      eventDatetime: this.formattedEventDatetime,
      id: this.searchedId,
      originalValue: this.searchedOriginalValue,
      newValue: this.searchedNewValue,
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      orderBy: this.orderBy,
      team:
        this.data instanceof UserAuditData
          ? isNoTeamRoleSelected
            ? 'no_team'
            : this.data.team?.id
          : null,
      roleCode:
        this.data instanceof UserAuditData ? (isNoTeamRoleSelected ? null : this.data.role) : null,
    };
  }

  private resolveDataTitle(): string {
    const trans = (key: string): string => this.translateService.instant(key);
    const typeTrans = trans(DATA_TYPE_TRANSLATIONS_MAPPER[this.auditDataType]);
    if (this.data instanceof UserAuditData) {
      return this.selectedUser?.displayInfo() || `${this.data.role || trans('ALL')} ${typeTrans}`;
    } else if (this.data instanceof NonUserAuditData) {
      return (this.auditDataId && this.data.dataValue.name) || `${trans('ALL')} ${typeTrans}`;
    }
    return typeTrans;
  }
}
