import { EAuthStatus, EAuthTitle, EAuthType } from '../enums';
import { DateRange } from 'app/common/types';
import { ColumnPreference } from 'app/modules/data-model/inquiries-table-preference/inquiries-table-preferences';

export interface IAuthModel {
  id: string;
  type: EAuthType;
  title?: EAuthTitle;
  requestedBy?: string;
  team?: string;
  specialization?: string;
  date?: string | Date;
  assignedTo?: string;
  status?: EAuthStatus;
  role: string;
  company?: string;
  therapeuticArea?: string;
  products?: number[];
  category?: string;
  topic?: string;
  interactionId?: number;
  newInteractionId?: number;
  interactionInitialText?: string;
  interactionTitle?: string;
  gender?: string;
  email?: string;
  department?: string;
  jobDescription?: string;
  userTitle?: string;
  handledTs?: string | Date;
}

export interface IAuthFilters {
  dateRange?: DateRange;
  approvedDate?: DateRange;
  team?: string | string[];
  type?: string | string[];
  requestedBy?: string;
  requestedFor?: string;
  assignedTo?: string;
  status?: string;
  myTeam?: boolean;
  role?: string;
  onlyOpen?: boolean;
  id?: number;
}

export interface IAuthSettings {
  displayedColumns: string[];
  defaultPreferences: ColumnPreference[];
}

export namespace IAuthFilters {
  export function defaults(): IAuthFilters {
    return {};
  }
}

export const DISPLAY_COLUMNS_LIST = [
  'id',
  'type',
  'requestedBy',
  'requestedFor',
  'team',
  'date',
  'approvedDate',
  'assignedTo',
  'role',
  'status',
];

export namespace IAuthSettings {
  export function defaults(): IAuthSettings {
    return {
      displayedColumns: DISPLAY_COLUMNS_LIST,
      defaultPreferences: [
        { name: 'id', checked: true, label: 'ID', order: 1, key: 't_id' },
        { name: 'type', checked: true, label: 'Type', order: 2, key: 'type' },
        { name: 'requestedBy', checked: true, label: 'Requestor', order: 3, key: 'requestedBy' },
        {
          name: 'requestedFor',
          checked: true,
          label: 'Requested for',
          order: 4,
          key: 'requestedFor',
        },
        { name: 'team', checked: true, label: 'Team', order: 5, key: 'team' },
        { name: 'date', checked: true, label: 'REQUESTED_DATE', order: 6, key: 'date' },
        {
          name: 'approvedDate',
          checked: true,
          label: 'REVIEWED_DATE',
          order: 7,
          key: 'approvedDate',
        },
        { name: 'assignedTo', checked: true, label: 'Assigned_To', order: 8, key: 'assignedTo' },
        { name: 'role', checked: true, label: 'Role', order: 9, key: 'role' },
        { name: 'status', checked: true, label: 'Status', order: 10, key: 'status' },
      ],
    };
  }
}
