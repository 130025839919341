import { PermissionHandler } from 'app/auth/auth.service';
import { MedisNavigationItem, filter, group, item, urlItem } from '../types';
import {
  PERM_ADMINISTRATE_SYSTEM,
  PERM_VIEW_USER_ADMIN,
} from 'app/common/common/constants/permissions';

function commonUserAdminNavigation(): MedisNavigationItem {
  const children: MedisNavigationItem[] = [
    urlItem('users', 'USERS', 'fa-users', '/administration/users'),
    urlItem('sync', 'SYNC', 'fa-exchange-alt', '/administration/syncs/users'),
  ];
  return group('sections', ' ', '', children, [PERM_VIEW_USER_ADMIN]);
}

export function userAdminSyncNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  return filter([commonUserAdminNavigation()], permissions);
}

export function userAdminNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  const children: MedisNavigationItem[] = [
    item('active', 'Active', 'fa-user-friends'),
    item('inactive', 'INACTIVE', 'fa-user-slash'),
    item('no_teams', 'NO_TEAMS', 'fa-user'),
    item('all', 'ALL', 'fa-users'),
  ];
  const userAdminNavigation: MedisNavigationItem[] = [
    commonUserAdminNavigation(),
    group('users', 'FILTER_BY', '', children, [PERM_VIEW_USER_ADMIN]),
  ];

  return filter(userAdminNavigation, permissions);
}

export function hcAdminNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  const hcpNavigation = group('users', 'HCP_ADMINISTRATION', '', [
    urlItem('enquiriesList', 'ENQUIRERS_LIST', 'fa-user-friends', '/administration/hcps', [
      PERM_VIEW_USER_ADMIN,
    ]),
    urlItem(
      'specializations',
      'SPECIALIZATIONS',
      'fa-book-medical',
      '/administration/specializations',
      [PERM_ADMINISTRATE_SYSTEM]
    ),
  ]);

  return filter([hcpNavigation], permissions);
}
