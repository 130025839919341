import { Component } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-page-access-denied',
  templateUrl: './page-access-denied.component.html',
})
export class PageAccessDeniedComponent {
  constructor(private authService: AuthService) {}

  navigateToPermittedPage(): void {
    this.authService.fetchUserAndNavigateToPermittedPage();
  }
}
